.info-container {
	margin-right: 40px;

	.bscIcon {
		width: 18px;
		height: 18px;
	}
	.bacIcon-word {
		font-size: 18px;
	}
	.chain,.address {
		background: #0cf0b2;
		border-radius: 10px;
	}
	.chain {
		// width: 86px;
		height: 40px;
		background: #404040;
		padding: 0 11px 0 14px;
		color: #FFF;
		border-radius: 10px;
	}
	.address {
		padding: 0 16px;
	}
}

.connectBtn {
	padding: 0 16px;
	width: 198px !important;
	height: 40px !important;
	background: #7626FF !important;
	border-radius: 10px !important;
	border: none !important;
	font-family: Arial Black;
	font-size: 18px !important;
	color: #00FFAB !important;
}
@media only screen and (max-width: 1000px) {
	.info-container {
		justify-content: end;
		padding: 0 10px;
		margin-top: 20px;

		.bscIcon {
			width: 12px;
			height: 12px;
		}
		.bacIcon-word {
			font-size: 12px;
		}
		.chain,.address {
			background: #0cf0b2;
			border-radius: 10px;
		}
		.chain {
			height: 25px;
			background: #404040;
			padding: 8px 6px;
			color: #FFF;
			border-radius: 10px;
		}
		.address {
			padding: 0 16px;
		}

		.connectBtn {
			padding: 0 14px !important;
			width: 129px !important;
			height: 26px !important;
			background: #7626FF !important;
			border-radius: 10px !important;
			border: none !important;
			font-family: Arial Black;
			font-size: 12px !important;
			color: #00FFAB !important;
		}
	}
}
