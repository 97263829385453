@import 'assets/fonts/fonts.css';

* {
  outline: none;
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}

*::-webkit-scrollbar {
	display: none !important;
}

html,
body,
#root {
 height: 100%;
 font-size: 12px;
 font-family: "Archivo",-apple-system, BlinkMacSystemFont;
 font-weight: 400;
 color: #000000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;
  /* background: linear-gradient(180deg, rgba(140, 246, 244, 0.75) 0%, rgba(255, 255, 255, 0.75) 100%), #2F86FF; */
  /* background-blend-mode: lighten, normal; */
	background-image: url(../src/assets/images/background.png);
	background-size: 100% 100%;
}

body {
  background: linear-gradient(180deg, rgba(140, 246, 244, 0.75) 0%, rgba(255, 255, 255, 0.75) 100%), #2F86FF;
  margin: 0;
  box-sizing: border-box;
}

.app {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-flow: column;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background-size: cover;
  overflow: hidden;
  transition: all ease 0.33ms;
}

.container{
	width: 100%;
	max-width: 1440px;
}

.font-12 {
	font-size: 12px;
}

.font-14 {
	font-size: 14px;
}

.font-16 {
	font-size: 16px;
}

.font-17 {
	font-size: 17px;
}

.font-18 {
	font-size: 18px;
	line-height: 20px;
}

.font-26 {
	font-size: 26px;
}

.font-28 {
	font-size: 28px;
}

.font-32 {
	font-size: 32px;
}

.font-36 {
	font-size: 36px;
}

.font-48 {
	font-size: 48px;
	line-height: 50px;
}

.color1 {
	color: #000000!important;
}

.color3 {
	color: #FFFFFF!important;
}

.color2 {
	color: rgba(0, 0, 0, 0.54);
}

.color4 {
	color: #151515;
}

.color5 {
	color: #00090F;
}

.color6 {
	color: rgba(0, 0, 0, 0.38);
}

.color7 {
	color: rgba(0, 0, 0, 0.87);
}

.color8 {
	color: #381099;
}

.font-family-Archivo {
 	font-family: 'Archivo',-apple-system, BlinkMacSystemFont;
 }

 .font-family-ArchivoBlack {
 	font-family: 'ArchivoBlack';
 }
 
 .font-weight-4 {
 	font-weight: 400;
 }
 
 .font-weight-6 {
 	font-weight: 600;
 }
 
 .font-weight-9 {
 	font-weight: 900;
 }
 
 .font-weight-b {
 	font-weight: bold;
 }
 
 .mt-2 {
 	margin-top: 2px;
 }
 
 .mt-6 {
	 margin-top: 6px;
 }
 
 .mt-8 {
	 margin-top: 8px;
 }
 
 .mt-10 {
	 margin-top: 10px;
 }
 
.mt-12 {
	margin-top: 12px;
}

.mt-14 {
	margin-top: 14px;
}

.mt-16 {
	margin-top: 16px;
}

.mt-20 {
	margin-top: 20px;
}

.mt-24 {
	margin-top: 24px;
}

.mt-26 {
	margin-top: 26px;
}

.mt-30 {
	margin-top: 30px;
}

.mt-40 {
	margin-top: 40px;
}

.ml-8 {
	margin-left: 8px;
}

.ml-12 {
	margin-left: 12px;
}

.ml-20 {
	margin-left: 20px;
}

.ml-24 {
	margin-left: 24px;
}

.ml-28 {
	margin-left: 28px;
}

.ml-30 {
	margin-left: 30px;
}

.mr-6 {
	margin-right: 6px;
} 

.mr-8 {
	margin-right: 8px;
} 

.mr-10 {
	margin-right: 10px;
} 

.bg1 {
	background: #FFFFFF;
}

.border-radius-4 {
	border-radius: 4px;
}

.border-radius-8 {
	border-radius: 8px;
}

.flex {
	display: flex;
}

.flex-1 {
	flex: 1;
}

.flex-2 {
	flex: 2;
}

.flex-justify-content-center {
	justify-content: center;
}

.flex-justify-content-between {
	justify-content: space-between;
}

.flex-justify-content-around {
	justify-content: space-around;
}

.flex-justify-content-end {
	justify-content: flex-end;
}

.flex-justify-content-start {
	justify-content: flex-start;
}

.flex-align-items-center {
	align-items: center;
}

.flex-align-items-end {
	align-items: flex-end;
}

.flex-align-items-start {
	align-items: flex-start;
}

.flex-direction-column {
	flex-direction: column!important;
}

.flex-wrap {
	flex-wrap: wrap;
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.text-center {
	text-align: center;
}

.ant-row {
	width: 100%;
}
.ant-image-img {
	width: auto!important;
}
.ant-btn:hover, .ant-btn:focus{
	border-color: transparent!important;
}
.ant-notification-notice-warning.ant-notification-notice{
	background: #F63C45!important;
}
.ant-notification-notice-info.ant-notification-notice {
	background: #2F86FF!important;
}
.ant-notification-notice-success.ant-notification-notice {
	background: #8CF6F4!important;
}
.ant-notification-notice {
	min-width: 246px!important;
	border-radius: 10px!important;
	min-height: 70px;
	padding: 0;
}
.ant-notification-notice-description{
	margin-left: 0!important;
	text-align: center;
	font-family: 'Inter'-apple-system,BlinkMacSystemFont!important ;
	font-size: 18px!important;
	font-weight: bold!important;
}
.ant-notification-notice-icon,
.ant-notification-notice-close{
	display: none!important;
}

@media only screen and (max-width: 1000px) {
	.container {
		background-image: url(../src/assets/images/background-app.png);
		background-size: 100% 100%;

		padding: 0 36px 36px;
	}
}

.web3modal-modal-lightbox {
	z-index: 2000 !important;
}
