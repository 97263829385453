.topbar{
	display: flex;
	align-items: center;
	height: 140px;
	justify-content: space-between;
	.topbar-logo {
		padding-left: 40px;

		img {
			height: 40px;
			width: auto;
		}
	}
	.logoIcon {
		margin-left: 40px;
		height: 56px;
	}
	.logoIcon-m {
		display: none!important;
	}
}


@media only screen and (max-width: 1000px) {
	.topbar{
		margin-right: -20px;
		height: auto;
		justify-content: end;
		.topbar-logo {
			display: none;
		}
		.logoIcon {
			display: none!important;
		}
		.logoIcon-m {
			display: block!important;
			margin-left: 10px;
			height: 44px;
		}
	}
}

.ant-image-img {
	width: auto!important;
}