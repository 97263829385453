.ido-content {
	// margin-top: 44px;
	padding-bottom: 80px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	.box {
		width: 900px;
		max-width: 100%;
		// margin-top: 72px;
		.box-top {
			width: 100%;
			height: 189px;
			background: rgba(0, 0, 0, 0.15);
			box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.15);
			border-radius: 10px 10px 0 0;
			.saleStarts-text {
				font-family: Arial Black;
				font-weight: 400;
				color: #00FFAB;
			}
			.countItem {
				// width: auto;
				width: 82px;
				height: 81px;
				background-image: url(../../assets/images/countItem-bg.png);
				background-size: 100% 100%;
				position: relative;
			}
			.countItem-text {
				font-size: 32px;
				font-family: Arial Black;
				font-weight: 400;
				color: #00FFAB;
			}
			.countItem-text-symbol {
				color: #8A8A8A;
				margin: 0 18px;
			}
			.timeIcon {
				margin-left: 10px;
				margin-right: 10px;
			}
		}
		.box-bot {
			width: 100%;
			margin-top: -6px;
			background: #3E3E3E;
			box-shadow: 0px 3px 9px 1px rgba(0, 0, 0, 0.15);
			border-radius: 15px;

			.box-bot-top {
				padding: 30px 46px 25px 25px;
				.box-bot-l {
					font-size: 32px;
					font-family: Arial Black;
					font-weight: 400;
					color: #00FFAB;
				}

				.box-bot-r {
					font-size: 17px;
					font-family: Kano;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 35px;
					.whitelist {
						margin-right: 28px;
					}
					.checkbox {
						width: 22px;
						height: 22px;
						margin-right: 12px;
					}
				}
			}
			.box-bot-top-app {
				display: none;
			}

			.box-info {
				// height: 386px;

				.infoBox-l {
					border-radius: 0 0 0 10px;

					&::after {
						content: '';
						position: absolute;
						left: auto;
						top: 10%;
						bottom: auto;
						right: 0;
						height: 80%;
						width: 1px;
						background-color: #8a8a8a;
					}
				}
				.infoBox-r {
					border-radius: 0 0 10px 0;
				}
				.infoBox {
					position: relative;
					padding: 0px 78px 30px 40px;

					.goal-desc {
						font-family: Kano;
						font-weight: 400;
						color: #8A8A8A;
						line-height: 18px;
					}
					.goal {
						font-family: Arial Black;
						font-weight: 400;
						font-size: 26px;
						color: #FFFFFF !important;
						line-height: 25px;
					}
					.join {
						font-size: 13px;
						font-family: Kano;
						font-weight: 400;
						color: #8A8A8A;
						line-height: 35px;
					}
					.minIcon {
						width: 20px;
						height: 20px;
					}
				}
			}
		}
	}
	.ant-input-number-group-addon {
		border-radius: 0 10px 10px 0!important;
		background: #6c6c6c !important;
		width: 25% !important;
	}
	.ant-input-group-addon {
		border-radius: 0 10px 10px 0!important;
		background: #6c6c6c !important;
		width: 25% !important;
		border: none!important;
	}
}

@media only screen and (max-width: 1000px) {
	.ido-content {
		margin-top: 20px;
		padding-bottom: 20px;
		.box {
			margin-top: 0;
			// padding: 0 10px;
			.box-top {
				height: 150px;
				border-radius: 10px;

				.countItem {
					width: 44px;
					height: 44px;
					background-image: url(../../assets/images/countItem-bg-app.png);
					background-size: 100% 100%;
					position: relative;
				}
				.countItem-text {
					font-size: 18px;
					font-family: Arial Black;
					font-weight: 400;
					color: #00FFAB;
				}
				.countItem-text-symbol {
					color: #979797;
					margin: 0 10px;
				}
			}
			.box-bot {
				padding: 15px 20px 25px 25px;
				.box-bot-top {
					display: none;
					flex-wrap: wrap;
					flex-direction: column-reverse;
				}
				.box-bot-top-app {
					display: block;
					// padding: 15px 20px 25px 25px;
					.box-bot-l {
						font-size: 17px;
						font-family: Kano;
						font-weight: 400;
						color: #FFFFFF;
						line-height: 35px;
					}
					.indao {
						margin-top: 40px; 
					}
					.box-bot-r {
						font-size: 12px;
						font-family: Kano;
						font-weight: 400;
						color: #FFFFFF;
						
						.checkbox {
							width: 14px;
							height: 14px;
							margin-right: 4px;
						}
					}
					.box-bot-l {
						font-size: 32px;
						font-family: Arial Black;
						font-weight: 400;
						color: #00FFAB;
					}
				}
			}
			.box-info {
				height: auto;
				// margin-top: 20px;
				flex-direction: column;
				.infoBox-l {
					border-radius: 10px;
					&::after {
						left: 3% !important;
						top: auto !important;
						bottom: 0 !important;
						right: auto !important;
						height: 2px !important;
						width: 94% !important;
					}
				}
				.infoBox-r {
					border-radius: 10px;
				}
				.infoBox-r {
					margin-top: 20px;
				}
				.infoBox {
					padding: 0 !important;
					padding-bottom: 20px !important;
					.goal {
						// margin-top: 20px;
						font-size: 21px;
						line-height: 40px;
					}
					.join {
						display: none;
					}
					.infoList {
						margin-top: 20px;
					}
				}
			}
		}
	}
	.confirmBtn {
		width: 100%!important;
	}
	.ant-input-number-group-addon {
		padding: 0 8px !important;
		border-radius: 0 10px 10px 0!important;
		background: #6c6c6c !important;
		width: 25% !important;
	}
	.ant-input-group-addon {
		padding: 0 8px !important;
		border-radius: 0 10px 10px 0!important;
		background: #6c6c6c !important;
		width: 25% !important;
		border: none!important;
	}
}

.ant-input-wrapper {
	width: 100%;
}
.ant-input {
	border-radius: 10px 0 0 10px !important;
	background: #6c6c6c !important;
	font-size: 19px !important;
	font-family: Arial Black !important;
	font-weight: 400 !important;
	color: #FFFFFF !important;
	border: none !important;

	&:focus {
		border: none !important;
		box-shadow: none !important;
	}
}

.ant-input-number-group-wrapper {
	width: 100%;
}
.ant-input-number-input {
	height: 50px!important;
}
.ant-input-number {
	border-radius: 10px 0 0 10px !important;
	background: #6c6c6c !important;
}
// .ant-input-number-group-addon {
// 	border-radius: 0 10px 10px 0!important;
// 	background: #6c6c6c !important;
// 	width: 25% !important;
// }
.ant-input-number,.ant-input-number-group-addon {
	border: none!important;
}
.ant-input-number:focus, .ant-input-number-focused {
	box-shadow: none!important;
}
.ant-input-number-input-wrap {
	font-size: 19px !important;
	font-family: Arial Black !important;
	font-weight: 400 !important;
	color: #FFFFFF !important;
}
.ant-input-number-disabled {
	background: #323232 !important;
}
.disabled-input-container .ant-input-number-group-addon {
	background: #323232 !important;
}
.confirmBtn {
	border-radius: 25px !important;
	height: 43px !important;
	font-family: Kano;
	font-size: 17px !important;
	font-weight: bold !important;
	// color: #8A8A8A !important;
	border-radius: 10px !important;
	// background: #323232 !important;
	border: 1px solid #323232 !important;
}

.time-item {
	position: absolute;
	bottom: -18px;

	font-size: 13px;
	font-family: Kano;
	font-weight: 400;
	color: #8A8A8A;
}

.balance {
	font-size: 13px;
	font-family: Kano;
	font-weight: 400;
	color: #8A8A8A;
}

.balance-amount {
	font-size: 13px;
	font-family: Arial Black;
	font-weight: 400;
	color: #FFFFFF;
}

.percent {
	font-size: 15px;
	font-family: Arial Black;
	font-weight: 400;
	color: #FFFFFF;
}
