@font-face
{
  font-family: 'ArchivoBlack';
  src: url('ArchivoBlack-Regular.ttf');
}
@font-face
{
  font-family: 'Archivo',-apple-system, BlinkMacSystemFont;
  src: url('Archivo.ttf');
}
@font-face
{
  font-family: 'Inter',-apple-system, BlinkMacSystemFont;
  src: url('Inter-Regular.ttf');
}
@font-face
{
  font-family: 'Poppins';
  src: url('Poppins.ttf');
}
