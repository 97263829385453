.invite-content {
	margin-top: 20px;
	padding-bottom: 80px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	// flex-direction: column;
	.box {
		width: 355px;
		max-width: 100%;
		position: relative;
		background: #3E3E3E;
		box-shadow: 0px 3px 9px 1px rgba(0, 0, 0, 0.15);
		border-radius: 15px;

		
		.idoTitle {
			font-size: 32px;
			line-height: 32px;
			font-family: Poppins;
			font-weight: bold;
			color: #00FFAB;
			padding: 0 40px 0 25px;
			margin: 37px 0 44px;
		}
		.idoTitle-app {
			display: none;
		}

		.diamond {
			position: absolute;
			top: 10px;
			right: 32px;
			font-size: 17px;
			font-family: Kano;
			font-weight: 400;
			color: #FFFFFF;
		}

		.box-bot-top {
			padding: 30px 47px 25px 25px;
			.box-bot-l {
				font-size: 32px;
				font-family: Arial Black;
				font-weight: 400;
				color: #00FFAB;
			}

			.box-bot-r {
				font-size: 17px;
				font-family: Kano;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 35px;
			}
		}

		.box-info {
			.infoBox {
				position: relative;
				padding: 0px 20px 30px 25px;

				.desc {
					font-size: 12px;
					font-family: Poppins;
					font-weight: 300;
					color: #8A8A8A;

					.text-indent {
						padding-left: 8px;
						text-indent: -8px;
					}
				}

				.goal-desc {
					font-family: Kano;
					font-weight: 400;
					color: #8A8A8A;
					line-height: 18px;
				}
				.goal {
					font-size: 32px;
					font-family: Arial Black;
					font-weight: 400;
					color: #FFFFFF !important;
					line-height: 25px;
					margin-top: 16px;
				}
				.minIcon {
					width: 20px;
					height: 20px;
				}
			}
		}
		
		.inviteBtn {
			height: 43px !important;
			font-family: Eagle Book;
			font-size: 17px !important;
			font-weight: bold !important;
			color: #010101 !important;
			border-radius: 10px !important;
			background: #1AE899 !important;
			border: 1px solid #1AE899 !important;
		}
	}
	.box-r {
		background: #E7E7E7;

		.idoTitle {
			color: #7626FF;
		}
		.box-info {
			.infoBox {
				padding: 0 10px 30px 18px;
				.desc {
					color: #000;
				}
				.goal-desc {
					color: #000;
				}

				.goal {
					color: #7626FF !important;
				}
			}
		}
		.inviteBtn {
			color: #1AE899 !important;
			background: #7626FF !important;
			border: 1px solid #7626FF !important;
		}
	}
	.box-l {
		margin-right: 70px;
	}
	
	.whatis {
		font-size: 17px;
		font-family: Poppins;
		font-weight: 300;
		color: #FFFFFF;
		line-height: 35px;
	}

	.whatis-desc {
		font-size: 12px;
		font-family: Poppins;
		font-weight: 300;
		color: #8A8A8A;
	}
}

.ant-input-number-group-wrapper {
	width: 100%;
}
.ant-input-number-input {
	height: 50px!important;
}
.ant-input-number {
	border-radius: 10px 0 0 10px !important;
	background: #6c6c6c !important;
}
.ant-input-number-group-addon {
	border-radius: 0 10px 10px 0!important;
	background: #6c6c6c !important;
	width: 25% !important;
}
.ant-input-number,.ant-input-number-group-addon {
	border: none!important;
}
.ant-input-number:focus, .ant-input-number-focused {
	box-shadow: none!important;
}
.ant-input-number-input-wrap {
	font-size: 19px !important;
	font-family: Arial Black !important;
	font-weight: 400 !important;
	color: #FFFFFF !important;
}
.ant-input-number-disabled {
	background: #323232 !important;
}
.disabled-input-container .ant-input-number-group-addon {
	background: #323232 !important;
}

@media only screen and (max-width: 1000px) {
	.invite-content {
		margin-top: 60px;
		padding-bottom: 20px;
		.box {
			margin-top: 0;
			width: 300px;
			
			.idoTitle {
				display: none;
			}
			.idoTitle-app {
				display: block;
				font-size: 20px;
				font-family: Poppins;
				font-weight: bold;
				color: #00FFAB;
				margin: 20px 0 25px;
			}

			.diamond {
				bottom: 12px;
				left: 20px;
				top: auto;
				right: auto;
			}
			.box-info {
				height: auto;
				flex-direction: column-reverse;
				.infoBox {
					padding: 0 22px 30px 20px;
					.goal {
						font-size: 19px;
						line-height: 20px;
					}
					.swap-rate {
						margin-top: 5px;
					}
					
					.infoList {
						margin-top: 20px;
					}
				}
			}
		}

		.box-l {
			margin-right: 0;
		}

		.box-r {
			margin-top: 46px;

			.idoTitle-app {
				color: #7626FF;
			}
		}

		.whatis {
			font-size: 17px;
			font-family: Poppins;
			font-weight: 300;
			color: #FFFFFF;
			line-height: 13px;
			margin: 28px 0 32px;
		}

		.whatis-desc {
			font-size: 12px;
			font-family: Poppins;
			font-weight: 300;
			color: #8A8A8A;
		}
	}
	.inviteBtn {
		margin-bottom: 24px;
	}
	.ant-modal {
		.invite-modal-content {
			text-align: center;
		}
	}
}

.time-item {
	position: absolute;
	bottom: -18px;

	font-size: 13px;
	font-family: Kano;
	font-weight: 400;
	color: #8A8A8A;
}

.balance {
	font-size: 13px;
	font-family: Kano;
	font-weight: 400;
	color: #8A8A8A;
}

.balance-amount {
	font-size: 13px;
	font-family: Arial Black;
	font-weight: 400;
	color: #FFFFFF;
}

.percent {
	font-size: 15px;
	font-family: Arial Black;
	font-weight: 400;
	color: #FFFFFF;
}

.invite-modal {
	padding: 16px;
	background: #3E3E3E;
	box-shadow: 0px 3px 18px 0px rgba(0, 0, 0, 0.42);
	border-radius: 10px;

	.invite-modal-title {
		font-size: 14px;
		font-family: Arial Black;
		font-weight: 400;
		color: #00FFAB;
	}
	.invite-modal-content {
		padding: 30px 0 23px;
		font-size: 14px;
		font-family: Poppins;
		font-weight: 300;
		color: #868686;
	}
}