.footerRow {
	position: absolute;
	bottom: 0;
	right: 0;
	left: 0;
	width: 100%;
	height: 60px;
	background: #F3F5FB;
}
.footerItem {
	margin-left: 24px;
	line-height: 20px;
}
.footerItem:first-child {
	margin-left: 0;
}

@media only screen and (max-width: 1000px) {
	.footerRow {
		flex-direction: column;
		justify-content: center;
		height: 90px;
	}
	.footerItem {
		margin-left: 12px;
	}
}